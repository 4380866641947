<template>
    <div>
      <div class="public-margin flex-right">
				<el-button @click="addClass" type="primary" icon="el-icon-plus" size="mini">新增分类</el-button>
			</div>
			<el-table
			:data="tableData"
			style="width: 100%"
			border
			height="calc(100vh - 240px)"
			stripe>
				<el-table-column
					prop="BasicNo"
					label="分类编号">
				</el-table-column>
				<el-table-column
					prop="BasicName"
					label="分类名称">
				</el-table-column>
				<el-table-column
					label="日结发送汇报(短信,微信)">
					<template slot-scope="scope">
						<div>{{ scope.row.DayEndSms | whether }}</div>	
					</template>
				</el-table-column>
				<el-table-column
					label="总部">
					<template slot-scope="scope">
						<div>{{ scope.row.HeadMark | whether }}</div>	
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="onEditClass(scope.row)">编辑</el-button>
						<el-button size="mini" type="danger" @click="onDeleteClass(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<EditDialog
				:formData="formData"
				:etitle="etitle" 
				:dialogTableVisible="showDialog" 
				:callFatherFunction="callFatherFunction"
				@closeDialog="onCloseDialog">
			</EditDialog>
    </div>
</template>

<script>
import service from '../../../../api/service.js';
import EditDialog from "./components/edit-dialog.vue";
export default {
    name: 'StoreWebCategory',

    components: { EditDialog },

    directives: {  },

    data() {
        return {
          tableData: [],
					formData: {},
					etitle: '',
					showDialog: false,
					
        };
    },

    mounted() {
      this.initPageData();  
    },
		
		filters: {
			whether(param){
				return param == 0 ? '否' : '是';
			}
		},

    methods: {
      async initPageData(){
      	try{
      		let { data } = await service.getItembasic();
					this.tableData = data;
      	}catch(e){
      		console.error(e);
      	}
      },  
			
			addClass(){
				this.etitle = '添加项目分类';
				this.formData = {
					BasicName: "",
					DayEndSms: 0,
				};
				this.showDialog = true;
			},
			
			onEditClass(event){
				this.etitle = '修改项目分类';
				this.formData = event;
				this.showDialog = true;
			},
			
			async onDeleteClass(event){
				this.$confirm(`是否删除该‘${event.BasicName}’`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					try{
						let { data, errcode, errmsg } = await service.deleteItembasic({ basic_guid: event.BasicGuid });
						if(errcode == 0){
							this.$message({ 
								message: '删除成功!',
							  type: 'success'
							});
							this.initPageData();
						}else{
							this.$message.error(errmsg);
						}
					}catch(e){
						console.error(e)
					}
				}).catch(() => {
					      
				});
			},
			
			onCloseDialog(){
				this.initPageData();
			},
			
			callFatherFunction(){
				this.showDialog = false;
			}
    },
};
</script>

<style lang="less" scoped>

</style>